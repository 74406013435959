import { Box } from '@rebass/grid'
import React from 'react'
import styled from 'styled-components'
import SvgCircleArrow from '../../svg/SvgCircleArrow'
import { theme } from '../../../util/style'
import { Link } from 'gatsby'

const ArrowText = ({ children, to, ...props }) => {
  if (!to) return null

  return (
    <Link to={to}>
      <Wrapper {...props}>
        <Box as="span" mr={[2]}>
          {children}
        </Box>
        <SvgCircleArrow />
      </Wrapper>
    </Link>
  )
}

export default ArrowText

const Wrapper = styled(Box)`
  display: flex;
  align-items: center;

  font-size: 19px;
  color: ${theme.colors.grey7};
  font-family: ${theme.font.fontFamilyRegular};

  cursor: pointer;
`
