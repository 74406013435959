import React from 'react'

const SvgCircleArrow = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ display: 'block' }}
    >
      <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" stroke="#81868B" />
      <path
        d="M13.2545 17.0235H11.9455L16.4455 12.5726H6.54546V11.5581H16.4455L11.9455 7.09082H13.2545L18.2455 12.0654L13.2545 17.0235Z"
        fill="#81868B"
      />
    </svg>
  )
}

export default SvgCircleArrow
